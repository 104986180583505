import Logo from '../components/Logo';

export const Header = () => {
  return (
    <div className="fixed top-0 left-0 z-20 w-full px-4 md:px-8">
      <div className="header-mask" />
      <header className="relative flex justify-between items-center w-full max-w-7xl h-14 mx-auto py-0">
        <div className="h-auto relative">
          <Logo withBeta={false} />
        </div>
      </header>
    </div>
  );
};
