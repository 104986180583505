import cn from 'utils/cn';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

export const Input = ({ className, ...props }: InputProps) => {
  return (
    <input
      {...props}
      className={cn(
        props.type === 'checkbox' ? '' : 'w-full',
        'py-1.5 px-2 bg-[#13121d] text-white rounded-md border border-main-border placeholder-secondary-gray placeholder-opacity-50 select:outline-none focus:ring-0 focus:border-main-color',
        className,
        'border-[#33323e] hover:border-[#6c6b7b] placeholder-main-airdrop',
      )}
    />
  );
};
