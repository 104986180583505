/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable react/no-unescaped-entities */
import { Button } from 'components/Button/Button';
import { Input } from 'components/Input';
import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip/Tooltip';
import { Header } from 'containers/Header';
import React, { forwardRef, useEffect, useState } from 'react';
import { DISCORD_INVITE_URL } from 'src/config';
import cn from 'utils/cn';
import { v4 as uuidv4 } from 'uuid';

import CloseSvg from 'public/static/close.svg?component';
import InfoSvg from 'public/static/info.svg?component';
import MinusSvg from 'public/static/minus.svg?component';
import PlusSvg from 'public/static/plus.svg?component';
import TwitterBirdSvg from 'public/static/twitter-bird.svg?component';

export type AnimateObject = {
  translate: {
    type: string;
    value: number;
    unit: string;
  };
  opacity: number;
};

const IsFaqSectionShow = forwardRef<
  HTMLButtonElement,
  {
    className?: string;
    isShownFaqSection?: string;
    sectionName?: string;
    title?: React.ReactNode;
    description?: React.ReactNode;
    onClick?: () => void;
  }
>(
  (
    { className, isShownFaqSection, sectionName, title, description, onClick },
    ref,
  ) => {
    return (
      <div className={`border-t border-t-[#33323e] ${className}`}>
        <button
          className="group flex justify-between items-center cursor-pointer w-full py-3 pr-6"
          type="button"
          onClick={onClick}
          ref={ref}
        >
          <div className="text-xl text-left text-white pr-4">{title}</div>
          <div>
            {isShownFaqSection !== sectionName ? (
              <PlusSvg className="h-10 w-10 text-main-color group-hover:scale-[1.25] duration-300 ease-out transition" />
            ) : (
              <MinusSvg className="h-10 w-10 text-main-color group-hover:scale-[1.25] duration-300 ease-out transition" />
            )}
          </div>
        </button>
        {isShownFaqSection === sectionName && (
          <div className="animate-faq-appear pb-6">
            <div className="h-auto overflow-hidden">{description}</div>
          </div>
        )}
      </div>
    );
  },
);

export type Animations =
  | 'simple'
  | 'rightLeft'
  | 'leftRight'
  | 'topDown'
  | 'downTop'
  | 'flash'
  | 'flip'
  | 'scale'
  | 'press';

const ListSvg = ({ className }: { className?: string }) => (
  <i className={cn('text-[24px] items-start', className)}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      className="icon"
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M10.25 5.75s-2.385 2.54-3 4.5l-1.5-1.5m8.5-.75a6.25 6.25 0 1 1-12.5 0 6.25 6.25 0 0 1 12.5 0Z"
      ></path>
    </svg>
  </i>
);

const GradientImgBorder = ({ alignment }: { alignment: 'top' | 'bottom' }) => (
  <div
    className={cn(
      'absolute h-px bg-gradient-to-r from-sky-300/0 via-[rgb(118,203,159,0.6)] to-sky-300/0',
      alignment === 'top'
        ? '-top-px left-20 right-11'
        : '-bottom-px left-11 right-20',
    )}
  />
);

const CopilotMember = ({
  src,
  name,
  position,
  twitter,
  description,
}: {
  src: string;
  name: string;
  position: string;
  twitter?: string;
  description: string;
}) => {
  return (
    <div className="flex flex-col items-center w-full max-w-[225x] xs:max-w-[200px] lg:w-auto">
      <div className="w-32 xs:w-40 h-32 xs:h-40 rounded-full overflow-hidden mb-2">
        <img src={src} alt={name} className="w-full h-full" />
      </div>
      <span className="text-white text-xl xs:text-2xl mb-1">{name}</span>
      <span className="flex items-center text-main-color text-sm xs:text-base mb-2 whitespace-nowrap ">
        {position}
        {twitter && (
          <a href={twitter} target="_blank" rel="noreferrer" className="ml-2">
            <TwitterBirdSvg className="w-4 h-4 fill-[#1d9cf0] hover:opacity-75" />
          </a>
        )}
      </span>
      <span className="text-center text-main-airdrop">{description}</span>
    </div>
  );
};

const PremiumWaitingListModal = ({
  children,
  onClick,
  onButtonThatOpenModalClick,
}: {
  children: JSX.Element;
  onClick: () => void;
  onButtonThatOpenModalClick: () => void;
}) => {
  return (
    <Modal
      dark
      onClick={onButtonThatOpenModalClick}
      render={({ close }) => {
        return (
          <div className="relative w-full s:w-[420px] max-w-2xl max-h-[100vh] overflow-y-auto py-8 px-6 bg-[#13121d] border border-[#33323e] rounded-md">
            <button
              className="absolute top-2 right-2"
              type="button"
              onClick={() => close()}
            >
              <CloseSvg className="w-6 h-6 fill-main-airdrop hover:fill-white" />
            </button>
            <p className="text-2xl font-semibold text-white mb-4 text-center">
              What’s your goals?
            </p>
            <p className="mt-4 text-left text-main-airdrop ">
              This plan is not available for immediate purchase.
              <br />
              <br /> We work only with individuals whose goals align with our
              expertise and values. Therefore, we carefully select with whom we
              work for this plan based on what they want to achieve. <br />
              <br />
              Let's discuss your goals and see if we're a good match. Open
              ticket on our Discord to chat with us.
            </p>

            <div className="flex justify-center w-full mt-4">
              <a
                href={DISCORD_INVITE_URL}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  onClick();
                }}
              >
                <Button theme="green" className="w-full">
                  Open Ticket
                </Button>
              </a>
            </div>
          </div>
        );
      }}
    >
      {children}
    </Modal>
  );
};

const WaitingListModal = ({
  children,
  inputValue,
  onInputChange,
  onClick,
  onCreate,
  onButtonThatOpenModalClick,
}: {
  children: JSX.Element;
  inputValue: string;
  onInputChange: (e: any) => void;
  onClick: () => void;
  onCreate: () => void;
  onButtonThatOpenModalClick: () => void;
}) => {
  const [contactAccepted, setContactAccepted] = useState(false);
  return (
    <Modal
      dark
      onClick={onButtonThatOpenModalClick}
      render={({ close }) => {
        return (
          <div className="relative w-full s:w-[430px] max-w-2xl max-h-[100vh] overflow-y-auto py-8 px-6 bg-[#13121d] border border-[#33323e] rounded-md">
            <button
              className="absolute top-2 right-2"
              type="button"
              onClick={() => close()}
            >
              <CloseSvg className="w-6 h-6 fill-main-airdrop hover:fill-white" />
            </button>
            <p className="text-2xl font-semibold text-white mb-4 text-center">
              Leave your email
            </p>
            <p className="mt-4 text-center text-main-airdrop">
              Leave your email to secure your spot on the waiting list. We’ll
              reach out as soon as it’ll become available.
            </p>
            <Input
              placeholder="mail@example.com"
              type="email"
              className="mt-2"
              value={inputValue}
              onChange={onInputChange}
            />
            {contactAccepted ? (
              <div>
                <div className="text-center mt-8 text-main-airdrop">
                  Your contacts are sent
                </div>
              </div>
            ) : (
              <div className="flex justify-center w-full mt-4">
                <Button
                  theme="green"
                  className="w-full"
                  disabled={!inputValue}
                  onClick={() => {
                    setContactAccepted(true);
                    onClick();
                  }}
                >
                  Notify Me
                </Button>
              </div>
            )}
          </div>
        );
      }}
    >
      {children}
    </Modal>
  );
};

export default function HomePage() {
  const [inputValue, setInputValue] = useState('');
  const [airdropUserId, setAirdropUserId] = useState('');

  useEffect(() => {
    const airdropUserId = localStorage.getItem('airdrop-user-id');

    if (airdropUserId) {
      setAirdropUserId(airdropUserId);
    } else {
      const newAirdropUserId = uuidv4();

      localStorage.setItem('airdrop-user-id', newAirdropUserId);

      setAirdropUserId(newAirdropUserId);
    }
  }, []);

  const handleInputPurchaseChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const [isShownFaqSection, setIsShownFaqSection] = useState('');

  const monthly = 'Ξ0.35';
  const lifeTime = 'Ξ1.4';
  const premium = 'Ξ3.8';

  return (
    <>
      <div>
        <main className="bg-[#13121d]">
          <Header />
          <div className="overflow-hidden">
            <section className="px-4 md:px-8 py-16 lg:pb-36 lg:pt-64 lg:min-h-screen lg:-mt-[72px]">
              <div className="max-w-6xl mx-auto z-10">
                <div className="flex flex-col items-center justify-center gap-4 lg:gap-16">
                  <div className="flex flex-col items-center w-full mt-6 md:mt-8 lg:mt-0 relative z-10">
                    <h1 className="font-semibold text-4xl md:text-6xl md:leading-tight text-center mb-6 animate-reveal-title text-white header relative">
                      Next Generation Wallet Manager
                    </h1>
                    <div className="text-xl md:text-2xl text-center text-main-airdrop mb-8 animate-reveal-description max-w-2xl">
                      Copilot is a self-custodial wallet manager. We provide a
                      service to simplify managing multiple wallets – withdraw
                      from CEX, create automation and engage in airdrops, all in
                      one interface.
                    </div>
                  </div>
                  <div className="relative mt-8">
                    <div className="absolute inset-[-100px] top-[-5%] left-[0] right-[0] mx-auto z-0">
                      <div className="apple-anime-container">
                        <div className="apple-anime-inside">
                          <div className="apple-anime-first"></div>
                          <div className="apple-anime-second"></div>
                          <div className="apple-anime-third"></div>
                        </div>
                      </div>
                    </div>

                    <div className="relative">
                      <GradientImgBorder alignment="top" />
                      <img
                        src="/images/wm.png"
                        alt="Main"
                        className="w-full rounded-xl"
                      />
                      <GradientImgBorder alignment="bottom" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="starry-sky-airdrop"></div>

            <section
              className="relative z-10 px-4 md:px-8 py-12 lg:py-28"
              id="wallets-stats"
            >
              <div className="max-w-5xl mx-auto">
                <div className="flex flex-col lg:flex-row items-center lg:gap-16 w-full ">
                  <div className="relative flex flex-col items-center lg:items-start">
                    <h1 className="font-semibold text-3xl md:text-5xl text-white text-center lg:text-left mb-6 ">
                      Withdraw from Exchanges
                      <span className="text-transparent bg-clip-text fancyText"></span>
                    </h1>
                    <p className="text-xl md:text-2xl text-center lg:text-left text-main-airdrop mb-8 lg:mb-0 animate-reveal-description max-w-[750px]">
                      You no longer have to withdraw manually from CEX with
                      thousands of clicks. Fund all your wallets in a few clicks
                      with Copilot.
                    </p>
                  </div>
                  <div className="relative overflow-hidden w-full">
                    <div className="heyLiniGrain rounded-xl"></div>
                    <div className="heyLiniGradient"></div>
                    <img
                      src="/images/cex-withdraw.png"
                      alt=""
                      className="w-full rounded-xl"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section
              className="relative z-10 px-4 md:px-8 py-12 lg:py-28"
              id="metamask-signing"
            >
              <div className="max-w-5xl mx-auto">
                <div className="flex flex-col lg:flex-row-reverse items-center lg:gap-16 w-full ">
                  <div className="relative flex flex-col items-center lg:items-start">
                    <h1 className="font-semibold text-3xl md:text-5xl text-white text-center lg:text-left mb-6 ">
                      Create Automation
                      <span className="text-transparent bg-clip-text fancyText"></span>
                    </h1>
                    <p className="text-xl md:text-2xl text-center lg:text-left text-main-airdrop mb-8 lg:mb-0 animate-reveal-description max-w-[750px]">
                      Connect to a myriad of dApps and use them whenever you
                      want with our automation. High gas when you sleep? No
                      problem. Automate when your wallet need to do an action.
                    </p>
                  </div>
                  <div className="relative overflow-hidden w-full">
                    <div className="heyLiniGrain rounded-xl"></div>
                    <div className="heyLiniGradient"></div>
                    <img
                      src="/images/activities.png"
                      alt="Activities and Dapps"
                      className="w-full rounded-xl"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section
              className="relative z-10 px-4 md:px-8 py-12 lg:py-28"
              id="airdrops"
            >
              <div className="max-w-5xl mx-auto">
                <div className="flex flex-col lg:flex-row items-center lg:gap-16 w-full ">
                  <div className="relative flex flex-col items-center lg:items-start">
                    <h1 className="font-semibold text-3xl md:text-5xl text-white text-center lg:text-left mb-6 ">
                      Engage in Airdrops
                      <span className="text-transparent bg-clip-text fancyText"></span>
                    </h1>
                    <p className="text-xl md:text-2xl text-center lg:text-left text-main-airdrop mb-8 lg:mb-0 animate-reveal-description max-w-[750px]">
                      You no longer need to manually switch between tabs and do
                      actions that will potentially can give you airdrops. Using
                      our automation you can create a task for your wallet to
                      engage in particular activities like swaps or liquidity on
                      mass amount of wallets.
                    </p>
                  </div>
                  <div className="relative overflow-hidden w-full">
                    <div className="heyLiniGrain rounded-xl"></div>
                    <div className="heyLiniGradient"></div>
                    <img
                      src="/images/create-task.png"
                      alt="Private Keys Stay Private"
                      className="w-full rounded-xl"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="relative z-10 px-4 md:px-8 py-12 lg:py-28">
              <div className="mx-auto">
                <div className="w-full flex flex-col items-center">
                  <div className="max-w-6xl flex flex-col items-center w-full text-center mb-8 lg:mb-16">
                    <h1 className="font-semibold text-3xl md:text-5xl text-white text-center mb-4 md:mb-8">
                      Our{' '}
                      <span className="text-transparent bg-clip-text fancyText">
                        Development Team
                      </span>
                    </h1>
                    <p className="max-w-[800px] text-xl md:text-2xl text-main-airdrop">
                      We've assembled a talented team of experts who are
                      passionate about providing cutting-edge technology
                      solutions.
                    </p>
                  </div>
                  <div className="flex w-full items-start justify-around flex-wrap gap-8 max-w-5xl">
                    <CopilotMember
                      src="/images/vice.png"
                      name="Vice"
                      position="Software Engineer"
                      description="Highly experienced and skilled developer who specializes in creating high-load systems and seamless user experiences."
                    />
                    <CopilotMember
                      src="/images/dru.png"
                      name="Dru"
                      position="Software Engineer"
                      description="Worked on countless products and has a wealth of expertise in developing advanced technology solutions."
                    />
                  </div>
                </div>
              </div>
            </section>

            <section
              className="relative z-10 px-4 md:px-8 py-12 lg:py-28"
              id="pricing"
            >
              <div className="mx-auto">
                <div className="w-full flex flex-col items-center">
                  <div className="max-w-6xl flex flex-col items-center w-full text-center mb-8 lg:mb-16">
                    <h1 className="font-semibold text-3xl md:text-5xl text-white text-center mb-4 md:mb-8">
                      Choose Your{' '}
                      <span className="text-transparent bg-clip-text fancyText">
                        Copilot
                      </span>
                    </h1>
                    <p className="max-w-[770px] text-xl md:text-2xl text-main-airdrop">
                      Unlock more opportunities and save tremendous amount of
                      time. Choose the plan that suits you best and start
                      enjoying.{' '}
                    </p>
                  </div>
                  <div className="flex flex-col l:flex-row items-center gap-8 l:gap-0">
                    <div className="plan bg-[#181622] relative rounded-tr-xl l:rounded-tr-none rounded-br-xl l:rounded-br-none rounded-tl-xl rounded-bl-xl">
                      <div className="heyLiniGradient rounded-tr-xl l:rounded-tr-none rounded-br-xl l:rounded-br-none rounded-tl-xl rounded-bl-xl"></div>
                      <div className="heyLiniGrain rounded-tr-xl l:rounded-tr-none rounded-br-xl l:rounded-br-none rounded-tl-xl rounded-bl-xl"></div>
                      <div className="text-left text-white text-[28px] relative z-50">
                        Monthly{' '}
                        {/* <span className="personalPro ml-1">Start</span> */}
                      </div>
                      <p className="mb-8 text-left text-main-airdrop relative z-50">
                        Perfect for upcoming.
                      </p>
                      <span className="flex gap-[8px] mb-8 text-main-airdrop relative z-50">
                        <strong className="text-white text-[48px] opacity-80 font-semibold">
                          {monthly}
                        </strong>{' '}
                        / month
                      </span>
                      <hr />
                      <div className="text-left mt-4 text-main-airdrop relative z-50">
                        <span>What’s included</span>
                        <ul className="flex flex-col mt-4 gap-4 min-h-[328px]">
                          <li className="flex items-center gap-[12px]">
                            <ListSvg />
                            Up to 100 wallets
                            <Tooltip label="The maximum number of wallets working at the same time. The total number of wallets is unlimited.">
                              <InfoSvg className="w-4 h-4 fill-main-airdrop" />
                            </Tooltip>
                          </li>
                          <li className="flex items-center gap-[12px]">
                            <ListSvg />
                            Automation
                          </li>
                        </ul>
                      </div>
                      <WaitingListModal
                        inputValue={inputValue}
                        onInputChange={(e) => handleInputPurchaseChange(e)}
                        onClick={() => {
                          const key = 'user-claimed-monthly';
                          const userClaimed = localStorage.getItem(key)
                            ? Number(localStorage.getItem(key))
                            : 0;
                          localStorage.setItem(key, String(userClaimed + 1));
                          setInputValue('');
                        }}
                        onCreate={() => {
                          setInputValue('');
                        }}
                        onButtonThatOpenModalClick={() => {
                          // fetchApi('/airdrops/purchase', {
                          //   isPublicApi: true,
                          //   method: 'POST',
                          //   body: {
                          //     price: monthly,
                          //     tier: 'monthly',
                          //     userId: airdropUserId,
                          //   },
                          // });
                        }}
                      >
                        <Button
                          theme="blue"
                          className="relative z-50 h-[46px] !text-lg py-2 px-4 tracking-tight active:scale-95"
                        >
                          Add Me To The Waiting List
                        </Button>
                      </WaitingListModal>
                    </div>
                    <div className="relative">
                      <div className="absolute inset-[-100px] top-[-5%] left-[0] right-[0] mx-auto z-[-1]">
                        <div className="apple-anime-container">
                          <div className="apple-anime-inside">
                            <div className="apple-anime-first"></div>
                            <div className="apple-anime-second"></div>
                            <div className="apple-anime-third"></div>
                          </div>
                        </div>
                      </div>
                      <div className="plan planHighlighted bg-[#181622] relative">
                        <div className="heyLiniGradient rounded-xl"></div>
                        <div className="heyLiniGrain rounded-xl"></div>
                        <div className="text-left text-white text-[28px] relative z-50">
                          <span className="oneTime">Lifetime</span>
                        </div>
                        <p className="mb-8 text-left text-main-airdrop relative z-50">
                          Made for serious users.
                        </p>
                        <span className="flex gap-[8px] mb-8 text-main-airdrop relative z-50">
                          <strong className="text-white text-[48px] font-semibold">
                            {lifeTime}
                          </strong>{' '}
                          / one time
                        </span>
                        <hr />
                        <div className="text-left mt-4 text-main-airdrop relative z-50">
                          <span>What’s included</span>
                          <ul className="flex flex-col mt-4 gap-4 min-h-[328px]">
                            <li className="flex items-center gap-[12px]">
                              <ListSvg className="text-main-color" />
                              <div>
                                Up to{' '}
                                <span className="text-main-color">100</span>{' '}
                                wallets
                              </div>
                              <Tooltip label="The maximum number of wallets working at the same time. The total number of wallets is unlimited.">
                                <InfoSvg className="w-4 h-4 fill-main-airdrop" />
                              </Tooltip>
                            </li>
                            <li className="flex items-center gap-[12px]">
                              <ListSvg className="text-main-color" />
                              Automation
                            </li>
                            <li className="flex items-center gap-[12px]">
                              <i>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 16 16"
                                  className="icon !w-6 !h-5"
                                >
                                  <path
                                    fill="#76cb9f"
                                    d="M5.75 10C10 10 10 5.75 10 5.75S10 10 14.25 10C10 10 10 14.25 10 14.25S10 10 5.75 10ZM4 1.75S4 4 1.75 4C4 4 4 6.25 4 6.25S4 4 6.25 4C4 4 4 1.75 4 1.75Z"
                                  ></path>
                                  <path
                                    stroke="#76cb9f"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="1.5"
                                    d="M5.75 10C10 10 10 5.75 10 5.75S10 10 14.25 10C10 10 10 14.25 10 14.25S10 10 5.75 10ZM4 1.75S4 4 1.75 4C4 4 4 6.25 4 6.25S4 4 6.25 4C4 4 4 1.75 4 1.75Z"
                                  ></path>
                                </svg>
                              </i>
                              More Coming Soon
                            </li>
                          </ul>
                        </div>
                        <WaitingListModal
                          inputValue={inputValue}
                          onInputChange={(e) => handleInputPurchaseChange(e)}
                          onClick={() => {
                            const key = 'user-claimed-life-time';
                            const userClaimed = localStorage.getItem(key)
                              ? Number(localStorage.getItem(key))
                              : 0;

                            localStorage.setItem(key, String(userClaimed + 1));
                            setInputValue('');
                          }}
                          onCreate={() => {
                            setInputValue('');
                          }}
                          onButtonThatOpenModalClick={() => {
                            if (
                              airdropUserId ===
                              '82b38955-3758-4991-9326-1dc3edb8221e'
                            )
                              return;
                          }}
                        >
                          <Button
                            theme="green"
                            className="relative z-50 h-[46px] w-full !text-lg py-2 px-4 tracking-tight active:scale-95 border border-[#33323e] cursor-pointer duration-200 hover:opacity-80"
                          >
                            Add Me To The Waiting List
                          </Button>
                        </WaitingListModal>
                      </div>
                    </div>

                    <div className="plan bg-[#181622] relative rounded-tl-xl l:rounded-tl-none rounded-bl-xl l:rounded-bl-none rounded-tr-xl rounded-br-xl">
                      <div className="heyLiniGradient rounded-tl-xl l:rounded-tl-none rounded-bl-xl l:rounded-bl-none rounded-tr-xl rounded-br-xl"></div>
                      <div className="heyLiniGrain rounded-tl-xl l:rounded-tl-none rounded-bl-xl l:rounded-br-none rounded-tr-xl rounded-br-xl"></div>
                      <div className="text-left text-white text-[32px] relative z-50">
                        Premium
                      </div>
                      <p className="mb-8 text-left text-main-airdrop relative z-50">
                        Made for professional users.
                      </p>
                      <span className="flex gap-[8px] mb-8 text-main-airdrop relative z-50">
                        <strong className="text-white text-[48px] font-semibold">
                          {premium}
                        </strong>{' '}
                        / one time
                      </span>
                      <hr />
                      <div className="text-left mt-4 text-main-airdrop relative z-50">
                        <span>What’s included</span>
                        <ul className="flex flex-col mt-4 gap-4 min-h-[328px]">
                          <li className="flex items-center gap-[12px]">
                            <ListSvg className="text-main-color" />
                            <div>
                              Everything in{' '}
                              <span className="text-main-color">Lifetime</span>
                            </div>
                          </li>
                          <li className="flex items-center gap-[12px]">
                            <ListSvg className="text-main-color" />
                            <div>
                              Up to{' '}
                              <span className="text-main-color">1000</span>{' '}
                              wallets
                            </div>
                          </li>
                          <li className="flex items-center gap-[12px]">
                            <i>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 16 16"
                                className="icon !w-6 !h-5"
                              >
                                <path
                                  fill="#76cb9f"
                                  d="M5.75 10C10 10 10 5.75 10 5.75S10 10 14.25 10C10 10 10 14.25 10 14.25S10 10 5.75 10ZM4 1.75S4 4 1.75 4C4 4 4 6.25 4 6.25S4 4 6.25 4C4 4 4 1.75 4 1.75Z"
                                ></path>
                                <path
                                  stroke="#76cb9f"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="1.5"
                                  d="M5.75 10C10 10 10 5.75 10 5.75S10 10 14.25 10C10 10 10 14.25 10 14.25S10 10 5.75 10ZM4 1.75S4 4 1.75 4C4 4 4 6.25 4 6.25S4 4 6.25 4C4 4 4 1.75 4 1.75Z"
                                ></path>
                              </svg>
                            </i>
                            More Coming Soon
                          </li>
                        </ul>
                      </div>
                      {/* <div className="relative z-50 text-main-airdrop">
                        <span className="text-white">
                          {personalSpotsLeft} spots left.
                        </span>{' '}
                        3/3 taken.
                        {personalSpotsClaimed}/{personalSpots} taken.
                      </div> */}
                      <PremiumWaitingListModal
                        onClick={() => {
                          const key = 'user-claimed-premium';
                          const userClaimed = localStorage.getItem(key)
                            ? Number(localStorage.getItem(key))
                            : 0;
                          localStorage.setItem(key, String(userClaimed + 1));
                          setInputValue('');
                        }}
                        onButtonThatOpenModalClick={() => {
                          // fetchApi('/airdrops/purchase', {
                          //   method: 'POST',
                          //   body: {
                          //     price: premium,
                          //     tier: 'premium',
                          //     userId: airdropUserId,
                          //   },
                          // });
                        }}
                      >
                        <Button
                          theme="blue"
                          className="relative z-50 h-[46px] !text-lg py-2 px-4 tracking-tight active:scale-95 border border-[#33323e] cursor-pointer duration-200 hover:opacity-80"
                        >
                          Add Me To The Waiting List
                        </Button>
                      </PremiumWaitingListModal>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div className="starry-sky-airdrop rotate-180" />

            <section className="px-8 py-16 md:py-36" id="faq">
              <div className="max-w-6xl mx-auto">
                <div className="mb-8 md:mb-20">
                  <h1 className="font-semibold text-3xl md:text-5xl text-center text-white uppercase mb-4 md:mb-6">
                    FAQ
                  </h1>
                  <p className="text-xl md:text-2xl text-center text-main-airdrop">
                    If you still have questions
                  </p>
                </div>
                <div className="max-w-3xl mx-auto">
                  <IsFaqSectionShow
                    className="border-t-0"
                    isShownFaqSection={isShownFaqSection}
                    sectionName="1"
                    title="What is Copilot?"
                    description={
                      <p className="text-lg text-main-airdrop pr-6">
                        Copilot is a self-custody wallet manager service that
                        help you more easily manage multiple wallets.
                        Self-custody means that we don't store your keys. They
                        are located encrypted on your own device and not on our
                        servers making it secure to store and manage.
                      </p>
                    }
                    onClick={() =>
                      isShownFaqSection !== '1'
                        ? setIsShownFaqSection('1')
                        : setIsShownFaqSection('')
                    }
                  />

                  <IsFaqSectionShow
                    className="border-t-0"
                    isShownFaqSection={isShownFaqSection}
                    sectionName="2"
                    title="How you store wallet private keys?"
                    description={
                      <p className="text-lg text-main-airdrop pr-6">
                        They are only stored securely encrypted in your browser,
                        which technically means they we don't have access to
                        them. Software use them to sign transactions on your
                        device in the background for automation.
                        <br />
                        <br />
                        We also have an option to use service without private
                        keys, than you will sign transactions manually with
                        metamask.
                      </p>
                    }
                    onClick={() =>
                      isShownFaqSection !== '2'
                        ? setIsShownFaqSection('2')
                        : setIsShownFaqSection('')
                    }
                  />

                  <IsFaqSectionShow
                    className="border-t-0"
                    isShownFaqSection={isShownFaqSection}
                    sectionName="3"
                    title="Whats the main purpose of the service?"
                    description={
                      <p className="text-lg text-main-airdrop pr-6">
                        To provide and easy and effective way to manage multiple
                        wallets.
                      </p>
                    }
                    onClick={() =>
                      isShownFaqSection !== '3'
                        ? setIsShownFaqSection('3')
                        : setIsShownFaqSection('')
                    }
                  />

                  <IsFaqSectionShow
                    className="border-t-0"
                    isShownFaqSection={isShownFaqSection}
                    sectionName="4"
                    title="How to contact team and support?"
                    description={
                      <p className="text-lg text-main-airdrop pr-6">
                        By email copilot.org@gmail.com
                      </p>
                    }
                    onClick={() =>
                      isShownFaqSection !== '4'
                        ? setIsShownFaqSection('4')
                        : setIsShownFaqSection('')
                    }
                  />

                  <IsFaqSectionShow
                    className="border-t-0"
                    isShownFaqSection={isShownFaqSection}
                    sectionName="5"
                    title="How many chains and apps you've integrated?"
                    description={
                      <p className="text-lg text-main-airdrop pr-6">
                        10+ chains and 30+ apps
                      </p>
                    }
                    onClick={() =>
                      isShownFaqSection !== '5'
                        ? setIsShownFaqSection('5')
                        : setIsShownFaqSection('')
                    }
                  />
                </div>
              </div>
            </section>
          </div>
        </main>
        <span className="grainFilter">
          <svg aria-hidden="true">
            <filter id="noiseFilter">
              <feTurbulence
                type="fractalNoise"
                baseFrequency="6.29"
                numOctaves="6"
                stitchTiles="stitch"
              ></feTurbulence>
            </filter>
          </svg>
        </span>
      </div>
    </>
  );
}
