import {
  FloatingPortal,
  arrow,
  flip,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react-dom-interactions';
import { useRef, useState } from 'react';

import s from './Tooltip.module.css';

interface Props {
  label: string | JSX.Element;
  children: React.ReactNode;
  className?: string;
  tooltipClassName?: string;
}

export default function Tooltip({
  label,
  children,
  className,
  tooltipClassName,
}: Props) {
  const arrowRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    context,
    x,
    y,
    reference,
    floating,
    strategy,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = useFloating({
    placement: 'top',
    middleware: [
      shift({ padding: 16 }),
      flip(),
      offset(15),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  const staticSide =
    {
      top: 'bottom',
      right: 'left',
      bottom: 'top',
      left: 'right',
    }[context.placement.split('-')[0]] ?? '';

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, { restMs: 40 }),
  ]);

  return (
    <>
      <div
        {...getReferenceProps({ ref: reference })}
        className={className}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        {children}
      </div>
      <FloatingPortal>
        {isOpen && (
          <div
            className={`${s.tooltipTransition} hidden m:block absolute z-[100] w-fit border bg-[#181622] border-[#33323e] text-white text-sm px-3 py-2 rounded animate-tooltip max-w-sm ${tooltipClassName}`}
            {...getFloatingProps({
              ref: floating,
              style: {
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
              },
            })}
          >
            {label}
            <div
              className={`absolute opacity-100 w-4 h-4 bg-[#181622] border-[#33323e] ${
                context.placement === 'bottom'
                  ? 'rotate-[135deg]'
                  : '-rotate-45'
              } shadow-lg rounded-bl border-b border-l `}
              ref={arrowRef}
              style={{
                left: arrowX != null ? `${arrowX}px` : '',
                top: arrowY != null ? `${arrowY}px` : '',
                right: '',
                bottom: '',
                [staticSide]: '-8.5px',
              }}
            ></div>
          </div>
        )}
      </FloatingPortal>
    </>
  );
}
