/* eslint-disable @typescript-eslint/ban-ts-comment */
import 'react-range-slider-input/dist/style.css';

import HomePage from '.';
import '../globals.css';

function MyApp() {
  return <HomePage />;
}

export default MyApp;
