import {
  FloatingFocusManager,
  FloatingNode,
  FloatingOverlay,
  FloatingPortal,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import React, { useCallback, useMemo, useRef, useState } from 'react';

// import s from './Modal.module.css';

interface Props {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  render: (props: {
    close: () => void;
    initialFocusRef: React.Ref<any>;
  }) => JSX.Element | React.ReactNode;
  children?: JSX.Element | React.ReactNode;
  triggerRef?: React.MutableRefObject<Element | null>;
  preventDismiss?: boolean;
  onClick?: (event: any) => void;
  onClose?: () => void;
  dark?: boolean;
}

const Modal = ({
  render,
  open: outerOpen,
  onOpenChange: onOpenOuterChange,
  children,
  triggerRef,
  preventDismiss,
  onClick,
  onClose,
  dark,
}: Props) => {
  const [innerOpen, setInnerOpen] = useState(false);
  const nodeId = useFloatingNodeId();
  const initialFocusRef = useRef(null);

  const open = outerOpen || innerOpen;

  const handleOpenChange = useCallback(
    (open: boolean) => {
      if (onOpenOuterChange) {
        onOpenOuterChange(open);
      } else {
        setInnerOpen(open);
      }

      if (!open) {
        onClose && onClose();
      }
    },
    [onClose, onOpenOuterChange],
  );

  const { reference, floating, context } = useFloating({
    open,
    onOpenChange: handleOpenChange,
    nodeId,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useRole(context),
    useClick(context),
    useDismiss(context, {
      bubbles: false,
      enabled: !preventDismiss,
    }),
  ]);

  const ModalContent = useMemo(
    () =>
      render({
        initialFocusRef,
        close: () => {
          handleOpenChange(!open);
        },
      }),
    [render, handleOpenChange, open],
  );

  const props = (() => {
    if (onClick) {
      return {
        onClick,
      };
    }

    return {};
  })();

  return (
    <FloatingNode id={nodeId}>
      {children
        ? React.cloneElement(children as React.ReactElement, {
            ...getReferenceProps({
              ref: (node) => {
                reference(node);

                if (triggerRef) {
                  triggerRef.current = node;
                }
              },
              ...props,
            }),
          })
        : null}
      <FloatingPortal>
        {open && (
          <FloatingOverlay
            lockScroll
            style={{
              display: 'grid',
              placeItems: 'center',
              background: dark ? 'rgba(0, 0, 0, 0.8)' : 'rgba(0, 0, 0, 0.8)',
              zIndex: 30,
              backdropFilter: dark ? 'blur(8px)' : undefined,
            }}
          >
            <FloatingFocusManager
              context={context}
              initialFocus={initialFocusRef}
            >
              <div
                {...getFloatingProps({
                  ref: floating,
                  className: 'grid',
                })}
              >
                {ModalContent}
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        )}
      </FloatingPortal>
    </FloatingNode>
  );
};

export default Modal;
