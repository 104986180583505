import React, { forwardRef } from 'react';
import cn from 'utils/cn';

import LoadingSvg from 'public/static/loading.svg?component';

import s from './Button.module.css';

export const Button = forwardRef<
  HTMLButtonElement,
  {
    className?: string;
    theme?: 'green' | 'blue' | 'red';
    onClick?: (event: any) => void;
    children: React.ReactNode | JSX.Element;
    type?: 'submit' | 'button';
    isLoading?: boolean;
    disabled?: boolean;
    size?: 'sm' | 'md' | 'lg';
  }
>(
  (
    {
      className,
      theme = 'main',
      isLoading,
      disabled,
      onClick,
      children,
      type = 'button',
      size = 'md',
      ...props
    },
    ref,
  ) => {
    let btnClass = '';
    let loadingClass = '';

    if (theme === 'green') {
      btnClass = `${
        isLoading ? 'bg-transparent opacity-75' : 'bg-transparent'
      } border border-[#244835] text-[#a2ffcf] hover:bg-[#121f18]`;
    } else if (theme === 'blue') {
      btnClass = `${
        isLoading ? 'bg-transparent opacity-75' : 'bg-transparent'
      } border border-[#33323e] text-white hover:bg-[#211f2d]`;
    } else if (theme === 'red') {
      btnClass = `${
        isLoading ? 'bg-transparent opacity-75' : 'bg-transparent'
      } border border-[#642028] text-red hover:bg-[#31181b]`;
    }

    if (theme === 'green') {
      loadingClass = 'fill-main-color';
    } else if (theme === 'blue') {
      loadingClass = 'fill-white';
    } else if (theme === 'red') {
      loadingClass = 'fill-white';
    }

    if (size === 'sm') {
      btnClass += ' py-[2px] px-[6px] text-sm';
    } else if (size === 'md') {
      btnClass += ' text-base py-[7px] px-2.5';
    } else if (size === 'lg') {
      btnClass += ' px-4 py-2 text-lg';
    }

    return (
      <button
        {...props}
        disabled={disabled || isLoading}
        className={cn(
          'flex items-center justify-center font-medium rounded-md whitespace-nowrap duration-200 focus:outline-none focus:shadow-outline',
          `${btnClass} ${className} ${s.btn}`,
        )}
        onClick={onClick}
        type={type}
        ref={ref}
      >
        {isLoading ? (
          <LoadingSvg className={`w-6 h-6 ${loadingClass}`} />
        ) : (
          children
        )}
      </button>
    );
  },
);
